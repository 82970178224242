<template>
  <div>
    <CustomTabs :tabList="tabList" @activeTab="activeTab"></CustomTabs>
    <CustomData :dataList="dataList"></CustomData>
    <CustomCard title="" height="300px">
      <pieChart :echartData="pieChartData" :legendShow="true"></pieChart>
    </CustomCard>
  </div>
</template>
<script>
import CustomTabs from "../CustomTabs.vue";
import CustomCard from "../CustomCard.vue";
import CustomData from "../CustomData.vue";
import pieChart from "@/components/chart/pieChart.vue";
import { handlePieList, formatFieldsList2 } from "js/utils.js";
import { GetCommonInfo } from "@/service";
import { mapState } from "vuex";
export default {
  props: ["detailId", "polygon"],
  data() {
    return {
      ytfbDetailFields: ["大类", "大类数量"],
      tabList: [
        { label: "金融银行", name: "金融银行" },
        { label: "美食餐饮", name: "美食餐饮" },
        { label: "生活服务", name: "生活服务" },
        { label: "宾馆酒店", name: "宾馆酒店" },
        { label: "医疗卫生", name: "医疗卫生" },
        { label: "体育休闲", name: "体育休闲" },
      ],
      dataList: [],
      ytfbChartData: {},
      pieChartData: [{}],
      ytfbDetailMiddleTypeFields: ["中类", "中类数量"],
      ytfbDetailSmallTypeFields: ["小类", "小类数量"],
    };
  },
  computed: {
    ...mapState(["tabsDetailFields", "tabsIdentify", "tabsIdentify2"]),
  },
  watch: {
    detailId() {
      this.initData();
    },
  },
  methods: {
    initData() {
      this.getBaseinfo();
      this.getYtfbDetailMiddleType(this.tabList[0].name);
    },
    async activeTab(name) {
      this.$emit("startLoading");
      await this.getYtfbDetailMiddleType(name);
      this.$emit("endLoading");
    },
    async getBaseinfo() {
      let identify = this.tabsIdentify2.format;
      let params = {
        identify: "sg-data-位置-业态分布",
        fields: [
          "美食餐饮",
          "生活服务",
          "宾馆酒店",
          "医疗卫生",
          "体育休闲",
          "金融银行",
        ],
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        const data = res.data
        let details = data.items ? res.data.items[0] : [];
        const fields = data.fields
        for (let i = 0; i < fields.length; i++) {
          let item = details[i];
          this.dataList.push({
            title: fields[i],
            value: item,
            unit: "个",
          });
        }
      }
    },
    // 商圈-业态分布-中类
    async getYtfbDetailMiddleType(type) {
      let res = await GetCommonInfo({
        identify: "sg-data-位置-业态详细分布情况",
        fields: [`${type}中类名称`, `${type}中类数量`],
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          }
        ],
        sort: [
          "value1", // 排序字段
          "desc", // 升序 'asc'; 降序 'desc'
        ],
        pages: {
          page: 1,
          size: 10,
        },
      });
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data || {});
        this.pieChartData = list.map((item) => {
          return {
            name: item[res.data.fields[0]],
            value: item[res.data.fields[1]],
          };
        });
      }
    },
  },
  mounted() {
    this.initData();
  },
  components: {
    CustomTabs,
    CustomData,
    pieChart,
    CustomCard,
  },
};
</script>
