<template>
  <div class="site-wrap detail-main">
    <Header :mode="2" detailType="商场" :searchKey="searchKey"></Header>
    <MenuSide
      :list="menuList"
      :activeMenu="activeMenu"
      @isOpen="isMenuOpen"
      @onselect="menuSelect"
    ></MenuSide>
    <div
      class="main-scroll"
      ref="scroll"
      :class="menuOpen ? '' : 'main-scroll-change'"
    >
      <div class="map-size">
        <MapPanelLittle
          :id="1"
          :markers="mapPoints"
          v-if="mapPoints.length != 0"
        ></MapPanelLittle>
      </div>
      <div class="main-size">
        <!--isVip：是否为VIP身份；collectShow：是否显示收藏按钮；claimShow：是否显示认领按钮；shareShow：是否显示分享按钮；-->
        <SummaryCard
          :cardType="4"
          :cardInfo="detailInfo"
          :isVip="false"
          :collectShow="true"
          :claimShow="false"
          :shareShow="true"
          :detailID="mallID"
        ></SummaryCard>
        <!-- 商场区位分析 -->
        <div class="content-item" id="quwei">
          <div class="item-title">
            商场区位分析
            <Poptip trigger="hover" placement="right" padding="0 0">
              <Icon type="ios-help-circle-outline" class="help_icon" />
              <div class="m-tips" slot="content">
                <p><span>商场所在区域周边3000米的区位分析</span></p>
              </div>
            </Poptip>
          </div>
          <div class="location-wrap">
            <div class="location">
              <div class="location-left">
                <MapPanel
                  ref="mapPanel"
                  :legendsObj="legendsObj"
                  :ignoreFitChina="true"
                  @loaded="handleMapLoaded"
                >
                </MapPanel>
              </div>
              <div class="location-right">
                <MapListTemplate>
                  <div class="left-panel" slot="leftPanel">
                    <div class="left-navs-tabs">
                      <ul>
                        <li
                          v-for="(item, index) in navs"
                          :key="'nav_' + index"
                          :class="{ cur: item.key === curNav }"
                          @click="curNav = item.key"
                        >
                          {{ item.label }}
                        </li>
                      </ul>
                    </div>
                    <div class="left-tabs-cont">
                      <!-- 基础信息 -->
                      <template v-if="curNav === 'basicFacilities'">
                        <BasicFacilities
                          v-if="wktPolygon"
                          :polygon="wktPolygon"
                          :id="mallID"
                        ></BasicFacilities>
                      </template>
                      <!-- 配套信息 -->
                      <template v-if="curNav === 'supportingFacilities'">
                        <SupportingFacilities
                          v-if="wktPolygon"
                          :polygon="wktPolygon"
                          :isShowOverView="false"
                          :detailId="mallID"
                        ></SupportingFacilities>
                      </template>
                      <!-- 业态分析 -->
                      <template v-if="curNav === 'formatAnalysis'">
                        <FormatAnalysis :detailId="mallID" :polygon="wktPolygon"></FormatAnalysis>
                      </template>
                    </div>
                  </div>
                </MapListTemplate>
              </div>
            </div>
          </div>
        </div>
        <!--业态构成-->
        <div class="content-item" id="yetai">
          <div class="item-title">
            业态构成
          </div>
          <div class="business-size" v-loading="yetaiLoadingStatus">
            <div class="business-left">
              <div
                class="left-item"
                v-for="(item, index) in echartData1"
                :key="'business_' + index"
              >
                <div class="left-item-title">{{ item.name }}</div>
                <div class="left-item-num">{{ item.value }}<span>家</span></div>
                <div class="left-item-line"></div>
              </div>
            </div>
            <div class="business-right">
              <PieChart :echartData="echartData1" class="charts-box"></PieChart>
            </div>
          </div>
        </div>
        <!--商户分布-->
        <div class="content-item" id="shanghu">
          <div class="item-title">商户分布</div>
          <div class="distribution-size" v-loading="shanghuLoadingStatus">
            <div class="distribution-nav">
              <div class="nav-size nav-floor">
                <div class="nav-title">商场楼层：</div>
                <div class="nav-item-box">
                  <div
                    class="nav-item"
                    :class="item.choose ? 'nav-choose' : ''"
                    v-for="(item, index) in distributionFloor"
                    :key="'floor_' + index"
                    @click="floorScreen(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="nav-size nav-type">
                <div class="nav-title">行业分类：</div>
                <div class="nav-item-box">
                  <div
                      class="nav-item"
                      :class="item.choose ? 'nav-choose' : ''"
                      v-for="(item, index) in distributionType"
                      :key="'type_' + index"
                      @click="typeScreen(item)"
                  >
                    {{ item.name }}({{ item.num }})
                  </div>
                </div>

              </div>
              <div class="search-box">
                <Input
                  v-model="merchantSearchWord"
                  clearable
                  placeholder="输入商户名、品牌名查询"
                  @on-enter="searchScreen"
                  @on-clear="clearSearchScreen"
                />
                <div class="search-btn" @click="searchScreen">
                  <img
                    class="search-icon"
                    src="../../assets/images/icon/search.png"
                  />
                </div>
              </div>
            </div>
            <!--列表-->
            <div class="distribution-list">
              <div
                class="list-item"
                v-for="(item, index) in merchantList"
                :key="'distribution_' + index"
              >
                <div class="img-size">
                  <img
                    class="list-item-img"
                    :src="item.imgUrl"
                    alt=""
                    v-if="item.imgUrl"
                  />
                  <template v-else>
                    <span class="pic-name text-ellipsis2 center-middle-box">{{ item.title }}</span>
                    <img class="list-item-img" src="@/assets/images/business/logobg2.png" alt="">
                  </template>
<!--                  <img-->
<!--                    class="list-item-img"-->
<!--                    src="../../assets/images/normal_brand.png"-->
<!--                    alt=""-->
<!--                    v-else-->
<!--                  />-->
                </div>
                <div class="list-item-content">
                  <div class="list-item-title">{{ item.title }}</div>
                  <div class="list-item-type">类别：{{ item.type }}</div>
                  <div class="list-item-floor">楼层：{{ item.floor }}</div>
                </div>
                <div class="list-more" @click="detailClick(item)">
                  查看详情
                  <img src="../../assets/images/icon/more.png" alt="" />
                </div>
              </div>
              <NoData class="no-data" v-if="merchantList.length === 0"></NoData>
            </div>
            <!--分页-->
            <div class="page-size" v-if="merchantList.length != 0 && pageNum">
              <Page
                prev-text=""
                next-text=""
                class-name="dt-pager"
                :show-total="true"
                :total="total"
                :current="pageNum"
                :page-size="pageSize"
                @on-change="changePage"
              >
                共{{ total }}条
              </Page>
            </div>
          </div>
        </div>
        <!--周边客群分析-->
        <div class="content-item" id="circumCustomer">
          <div class="item-title">
            周边客群分析
            <Poptip trigger="hover" placement="right" padding="0 0">
              <Icon type="ios-help-circle-outline" class="help_icon" />
              <div class="m-tips" slot="content">
                <p><span>商场所在区域周边3000米的客群分析</span></p>
              </div>
            </Poptip>
            <!-- <img
              class="g-vip-icon"
              :src="require('@/assets/images/vip/g-vip.png')"
              width="21"
              height="10"
            /> -->

             <Icon  type="ios-lock-outline"   class="g-vip-icon"/>
          </div>
          <section class="g-vip-section">
            <div role="button" class="g-vip-btn" @click="toVip">去升级</div>
            <div>
              <img
                :src="require('@/assets/images/demo_pointer.png')"
                width="464"
              />
            </div>
            <div class="diliver"></div>
          </section>
          <CircumCustomer v-if="mapPoints && mapPoints.length" :id="mallID" :identify="'商场'" :pos-info="{ geom: mapPoints[0].geometry }"></CircumCustomer>
        </div>
        <!--客流分析-->
        <div class="demo-item" id="keliu">
          <Icon type="ios-lock-outline" class="g-vip-icon2" />
          <!-- <img src="../../assets/images/demo/mall_detail_demo3.png" alt="" /> -->
          <img src="../../assets/images/demo/mall_detail_demo3_b.png" alt="" />
          <div class="demo-btn2" @click="vipClick"></div>
          <div class="anchor-box anchor-box1" id="商场到访">
            <!--demo图片，此div用于锚点定位-->
          </div>
          <div class="anchor-box anchor-box2" id="楼层到访">
            <!--demo图片，此div用于锚点定位-->
          </div>
          <div class="anchor-box anchor-box3" id="业态到访">
            <!--demo图片，此div用于锚点定位-->
          </div>
          <!-- <div class="anchor-box anchor-box4" id="客流来源分析"> -->
            <!--demo图片，此div用于锚点定位-->
          </div>
        </div>
        <!-- 客户画像 -->
        <div class="demo-item" id="khhx">
          <Icon type="ios-lock-outline" class="g-vip-icon3" />
          <!-- <img src="../../assets/images/demo/mall_detail_demo4.png" alt="" /> -->
          <img src="../../assets/images/demo/mall_detail_demo4_b.png" alt="" />
          <div class="demo-btn2" @click="vipClick"></div>
        </div>
        <!--到访分析-->
        <!-- <div class="demo-item" id="daofang">
          <img src="../../assets/images/demo/mall_detail_demo2.png" alt="" />
          <div class="demo-btn" @click="vipClick"></div>
          <div class="anchor-box anchor-box5" id="进店分析"> -->
            <!--demo图片，此div用于锚点定位-->
          <!-- </div>
          <div class="anchor-box anchor-box6" id="停留分析"> -->
            <!--demo图片，此div用于锚点定位-->
          <!-- </div> -->
        <!-- </div> -->
        <div class="demo-item custom-item" id="depth">
          <div class="anchor-box8" ref="offlineAnalysis" id="offlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <offline-analysis></offline-analysis>
          </div>
          <div class="anchor-box9" id="onlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <online-analysis></online-analysis>
          </div>
        </div>
        <div class="page-bottom"></div>
      </div>
       <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
    </div>
  </div>
</template>

<script>
import ModalUpgrade from "@/components/ModalUpgrade.vue"
import SummaryCard from "../../components/SummaryCard.vue";
import MenuSide from "../../components/MenuSide.vue";
import { GetCommonInfo, GetDetailBrowsingRecord } from "@/service";
import { formatFieldsList, cloneObj, formatFieldsList2 } from "js/utils.js";
import { any2geom, geom2wkt, wkb2geom, wkt2geom } from "js/map/map.utils.js";
import MapPanelLittle from "@/components/MapPanelLittle.vue";
import PieChart from "../../components/chart/pieChart.vue";
import CircumCustomer from "../../components/cards/CircumCustomer.vue";
import NoData from "../../components/NoData.vue";
import { List } from "echarts";
import MapPanel from "../../components/MapPanel.vue";
import BasicFacilities from "@/components/mall/BasicFacilities.vue";
import FormatAnalysis from "@/components/mall/FormatAnalysis.vue";
import * as turf from "@turf/turf";
import { openNewWindow } from "@/assets/javascript/utils.js";
import offlineAnalysis from "../../components/depthAnalysis/offline.vue";
import onlineAnalysis from "../../components/depthAnalysis/online.vue";
import SupportingFacilities from "@/components/location/SupportingFacilities.vue";

export default {
  metaInfo() {
    let list = ["查商场"];
    if (!this.mallName) {
      return null;
    }
    list.unshift(this.cityName);
    list.unshift(this.mallName);
    return {
      title: list.join(" - "),
      meta: [
        {
          name: "keywords",
          content:
            `千里目,${this.cityName},${this.mallName},商场数据,商场入驻品牌,商场商业业态,商户分布,商场楼层,商场配套,住宅小区,写字楼,商场,商超,便利店,学校,医院,公司企业,交通,客群特征,商业业态,商场到访客流,客流来源,用户停留时长,用户进场时段,新增客流,流失客流,客流统计,实时客流`,
        },
        {
          name: "description",
          content:
            `${this.cityName}${this.mallName}商业数据分析，包含商场商业业态构成、楼层数、楼层商户类型分布和数量、商场配套、住宅小区、写字楼、商场、商超、便利店、学校、医院、公司企业、交通、客群特征、商业业态、商场到访客流和来源、用户停留时长和进场时段、新增和流失客流等详细数据。`,
        },
       ]
    };
  },
  data() {
    return {
      isShow:false,
      menuOpen: true,
      mallID: "",
      mallName: "",
      wktPolygon: '',
      detailInfo: [],
      //背景地图数据
      mapPoints: [],
      //业态分析
      businessData: [],
      echartData1: [],
      // 左侧菜单选中值
      activeMenu: "",
      // 左侧菜单调整自检名称，动态路由使用
      componentName: "",
      // 左侧菜单列表，key值临时定义使用时需修改
      menuList: [
        {
          key: "quwei",
          name: "区位分析",
          title: "区位",
          isComponet: false,
        },
        {
          key: "yetai",
          name: "业态构成",
          title: "业态",
        },
        {
          key: "shanghu",
          name: "商户分布",
          title: "商户",
        },
        {
          key: "circumCustomer",
          name: "周边客群",
          title: "客群",
        },
        {
          key: "keliu",
          name: "到访分析",
          title: "客流",
          children: [
            {
              key: "商场到访",
              name: "商场到访",
              icon: require("@/assets/images/icon/menu_scdf.png"),
              isComponet: false,
            },
            {
              key: "楼层到访",
              name: "楼层到访",
              icon: require("@/assets/images/icon/menu_jk.png"),
              isComponet: false,
            },
            {
              key: "业态到访",
              name: "业态到访",
              icon: require("@/assets/images/icon/menu_xl.png"),
              isComponet: false,
            },
            // {
            //   key: "客流来源分析",
            //   name: "客流来源分析",
            //   icon: require("@/assets/images/icon/menu_md.png"),
            //   isComponet: false,
            // },
          ],
        },
        {
          key: "khhx",
          name: "客户画像",
          title: "深度",
        },
        // {
        //   key: "daofang",
        //   name: "到访分析",
        //   title: "到访",
        //   children: [
        //     {
        //       key: "进店分析",
        //       name: "进店分析",
        //       icon: require("@/assets/images/icon/menu_df.png"),
        //       isComponet: false,
        //     },
        //     {
        //       key: "停留分析",
        //       name: "停留分析",
        //       icon: require("@/assets/images/icon/menu_kh.png"),
        //       isComponet: false,
        //     },
        //   ],
        // },
        {
          key: "depth",
          name: "深度分析",
          title: "深度分析",
          children: [
            {
              key: "offlineAnalysis",
              name: "线下数据分析",
              icon: require("@/assets/images/icon/menu_xx.png"),
              isComponet: false,
            },
            {
              key: "onlineAnalysis",
              name: "线上营销分析",
              icon: require("@/assets/images/icon/menu_xs.png"),
              isComponet: false,
            },
          ],
        },
      ],

      distributionFloor: [{ name: "不限", choose: true }],
      distributionType: [{ name: "全部", num: 0, choose: true }],
      merchantSearchWord: "",
      merchantFloor: "",
      merchantType: "",
      merchantList: [],
      merchantFirst: true,

      pageNum: "",
      pageSize: 12,
      total: 0,
      legends: [
        {
          title: "住宅区",
          color: "#5F6AEB",
          key: "community",
        },
        {
          title: "商业区",
          color: "#986FFF",
          key: "business",
        },
        {
          title: "写字楼",
          color: "#FD767D",
          key: "office",
        },
        {
          title: "学校",
          color: "#FF9800",
          key: "school",
        },
        {
          title: "医院",
          color: "#FCC44D",
          key: "hospital",
        },
        {
          title: "地铁站",
          color: "#3CD19C",
          key: "subway",
        },
        {
          title: "公交站",
          color: "#28C7F3",
          key: "bus",
        },
      ],
      isMapLoaded: false,
      markerObj: {
        center: [],
        type: "",
        radius: 3000,
      },
      searchGeom: "",

      navs: [
        { label: "基础信息", key: "basicFacilities" },
        { label: "配套信息", key: "supportingFacilities" },
        { label: "业态分析", key: "formatAnalysis" },
      ],
      curNav: "basicFacilities",
      isSearchResult: false,
      yetaiLoadingStatus: false,
      shanghuLoadingStatus: false,
      searchKey: "",
      cityName:""
    };
  },
  computed: {
    scrollBox() {
      return this.$refs.scroll;
    },
    legendsObj() {
      return {
        title: "全部配套",
        list: this.legends,
      };
    },
    resultGeom() {
      if (this.isSearchResult) {
        return geom2wkt(this.searchGeom);
      }
      return "";
    },
  },
  methods: {
    isMenuOpen(isOpen) {
      this.menuOpen = isOpen;
    },
    menuSelect(name, isComponent) {
      if (isComponent) {
        // 线下营销或非锚点定位走动态路由
        this.componentName = name;
      } else {
        this.componentName = "";
        document.getElementById(name) &&
          document.getElementById(name).scrollIntoView({ behavior: "smooth" });
      }
    },
    toVip() {
      this.isShow= true;
      // window.open("/vip", "_blank");
    },
    //锚点和导航联动
    handleScroll() {
      if (this.componentName) return;
      //获取dom滚动距离
      const scrollTop = this.scrollBox.scrollTop;
      // 描点判断
      //商场区位分析
      let oneTop = this.$el.querySelector("#quwei").offsetTop;
      //客流分析
      let twoTop = this.$el.querySelector("#keliu").offsetTop;
      let twoOneTop = twoTop + this.$el.querySelector("#商场到访").offsetTop;
      let twoTwoTop = twoTop + this.$el.querySelector("#楼层到访").offsetTop;
      let twoThreeTop = twoTop + this.$el.querySelector("#业态到访").offsetTop;
      // let twoFourTop = twoThreeTop
      //   twoTop + this.$el.querySelector("#客流来源分析").offsetTop;
      //到访分析
      // let threeTop = this.$el.querySelector("#daofang").offsetTop;
      // let threeOneTop =
      //   threeTop + this.$el.querySelector("#进店分析").offsetTop;
      // let threeTwoTop =
      //   threeTop + this.$el.querySelector("#停留分析").offsetTop;
      //周边客群分析
      let fourTop = this.$el.querySelector("#circumCustomer").offsetTop;
      //业态构成
      let fiveTop = this.$el.querySelector("#yetai").offsetTop;
      //商户分布
      let sixTop = this.$el.querySelector("#shanghu").offsetTop;
      let sevenTop = this.$el.querySelector("#depth").offsetTop;
      let sevenOneTop =
        sevenTop + this.$el.querySelector("#offlineAnalysis").offsetTop;
      let sevenTwoTop =
        sevenTop + this.$el.querySelector("#onlineAnalysis").offsetTop;
      let khhxTop = this.$el.querySelector("#khhx").offsetTop
      if (scrollTop >= oneTop && scrollTop < fiveTop) {
        //滚动到达商场区位分析
        this.activeMenu = "quwei";
      } else if (scrollTop >= fiveTop && scrollTop < sixTop) {
        //滚动到达业态构成
        this.activeMenu = "yetai";
      }  else if (scrollTop >= sixTop && scrollTop < fourTop) {
        //滚动到达商户分布
        this.activeMenu = "shanghu";
      } else if (scrollTop >= fourTop && scrollTop < twoOneTop) {
        this.activeMenu = "circumCustomer";
      } else if (scrollTop >= twoOneTop && scrollTop < twoTwoTop) {
        //滚动到达客流分析--商场客流
        this.activeMenu = "商场到访";
      } else if (scrollTop >= twoTwoTop && scrollTop < twoThreeTop) {
        //滚动到达客流分析--楼层客流
        this.activeMenu = "楼层到访";
      } else if (scrollTop >= twoThreeTop && scrollTop < khhxTop) {
        //滚动到达客流分析--业态客流
        this.activeMenu = "业态到访";
      } else if (scrollTop >= khhxTop && scrollTop < sevenOneTop) {
        this.activeMenu = 'khhx'
      } else if (scrollTop >= sevenOneTop && scrollTop < sevenTwoTop) {
        this.activeMenu = 'offlineAnalysis'
      } else if (scrollTop >= sevenTwoTop) {
        this.activeMenu = 'onlineAnalysis'
      }
      // else if (scrollTop >= twoFourTop && scrollTop < threeOneTop) {
      //   //滚动到达客流分析--客流来源分析
      //   // this.activeMenu = "客流来源分析";
      // } else if (scrollTop >= threeOneTop && scrollTop < threeTwoTop) {
      //   //滚动到达到访分析--进店分析
      //   this.activeMenu = "进店分析";
      // }
      // else if (scrollTop >= twoFourTop && scrollTop < sevenTop) {
      //   //滚动到达到访分析--停留分析
      //   this.activeMenu = "停留分析";
      // } else if (scrollTop >= sevenOneTop && scrollTop < sevenTwoTop) {
      //   this.activeMenu = "offlineAnalysis";
      // } else if (scrollTop >= sevenTwoTop) {
      //   this.activeMenu = "onlineAnalysis";
      // }
    },
    //获取详情信息（汇总卡片信息）
    async getDetail() {
      let params = {
        identify: "sg-data-商场-基础信息",
        filters: [
          {
            field: "商场唯一code",
            cond: 0,
            value: [this.mallID],
          },
        ],
        fields: [
          "地址",
          "商业面积",
          "城市",
          "开发商",
          "发展阶段",
          "楼层数",
          "geom",
          "经营年限",
          "名称",
          "开业时间",
          "停车位",
          "图片",
          "商户数",
          "电话",
          "商场类型",
        ],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data || {});
        this.detailInfo = list[0] || [];
        this.mallName = this.detailInfo["名称"];

        //地图数据
        let polygon = any2geom(list[0]["geom"] || "");
        let features = [];
        let feature = {
          type: "Feature",
          geometry: JSON.parse(JSON.stringify(polygon)),
        };
        features.push(feature);
        this.mapPoints = features;

        const radius = 3000
        let center = feature.geometry.coordinates;
        let circle = turf.circle(center, radius / 1000, {
          steps: 256,
          units: "kilometers",
        });

        this.wktPolygon = geom2wkt(circle.geometry)
      }
    },

    //商户业态分析
    async getBusinessData() {
      this.yetaiLoadingStatus = true;
      let params = {
        identify: "sg-data-商场-商户业态",
        filters: [
          {
            field: "商场唯一code",
            cond: 0,
            value: [this.mallID],
          },
        ],
        fields: ["类型", "数量"],
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList(res.data || {});
        for (let i in list) {
          this.echartData1.push({
            name: list[i]["类型"],
            value: list[i]["数量"],
          });
        }
      }
      this.yetaiLoadingStatus = false;
    },

    //商户分布
    async getDistribution(isScreen) {
      this.shanghuLoadingStatus = true;
      //搜索
      let searchArr = [];
      if (this.merchantSearchWord != "") {
        searchArr.push(this.merchantSearchWord);
      } else {
        searchArr = [];
      }
      //商场楼层筛选
      let screenFloor = [];
      if (this.merchantFloor != "") {
        screenFloor.push(this.merchantFloor);
      } else {
        screenFloor = [];
      }
      //行业分类筛选
      let screenType = [];
      if (this.merchantType != "") {
        screenType.push(this.merchantType);
      } else {
        screenType = [];
      }

      let params = {
        identify: "sg-data-商场-商户业态-查看更多",
        filters: [
          {
            field: "商场唯一code",
            cond: 0,
            value: [this.mallID],
          },
          {
            field: "品牌名称",
            cond: 0,
            value: searchArr,
          },
          {
            field: "楼层",
            cond: 0,
            value: screenFloor,
          },
          {
            field: "行业分类",
            cond: 0,
            value: screenType,
          },
        ],
        fields: [
          "品牌id",
          "城市",
          "楼层",
          "商场楼层",
          "品牌名称",
          "品牌分类",
          "行业分类",
          "总的行业分类",
          "品牌url",
        ],
        // pages: {
        //   page: this.pageNum,
        //   size: this.pageSize,
        // },
      };
      if (this.pageNum) {
        params.pages = {
          page: this.pageNum,
          size: this.pageSize,
        };
      }
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        this.merchantList = [];
        let list = formatFieldsList(res.data || {});
        if (this.merchantFirst) {
          //行业分类
          this.distributionType = this.distributionType.concat(this.echartData1.filter(item => item.value).sort((a, b) => b.value - a.value).map(item => {
            item.choose = false
            item.num = item.value
            return item
          }))

          //楼层
          if (list[0]["商场楼层"]) {
            let floorArr = String(list[0]["商场楼层"]);
            floorArr = floorArr.split(",");
            for (let i in floorArr) {
              this.distributionFloor.push({
                name: floorArr[i],
                choose: false,
              });
            }
          }
          this.distributionType[0].num = list.length || 0;
          this.distributionType = JSON.parse(
            JSON.stringify(this.distributionType)
          );
          this.distributionFloor = JSON.parse(
            JSON.stringify(this.distributionFloor)
          );

          this.merchantFirst = false;
        }
        if (!isScreen) {
          this.total = list.length || 0;
          this.pageNum = 1;
          this.getDistribution(1);
          return false;
        }

        for (let i in list) {
          this.merchantList.push({
            id: list[i]["品牌id"],
            imgUrl: list[i]["品牌url"],
            title: list[i]["品牌名称"],
            type: list[i]["行业分类"],
            floor: list[i]["楼层"],
          });
        }
        //console.log(list,999)
        //this.merchantList = list || [];
      }
      this.shanghuLoadingStatus = false;
    },
    //商户分布（楼层筛选）
    floorScreen(item) {
      this.merchantFloor = item.name === "不限" ? "" : item.name;
      this.merchantFloor = this.merchantFloor.replace(/\s*/g, "");
      this.resetDistributionList();
      for (let i in this.distributionFloor) {
        if (this.distributionFloor[i].name === item.name) {
          this.distributionFloor[i].choose = true;
        } else {
          this.distributionFloor[i].choose = false;
        }
      }
      this.getDistribution();
    },
    //商户分布（行业分类）
    typeScreen(item) {
      this.merchantType = item.name === "全部" ? "" : item.name;
      this.merchantType = this.merchantType.replace(/\s*/g, "");
      this.resetDistributionList();
      for (let i in this.distributionType) {
        if (this.distributionType[i].name === item.name) {
          this.distributionType[i].choose = true;
        } else {
          this.distributionType[i].choose = false;
        }
      }
      this.getDistribution();
    },
    //商户分布（搜索）
    searchScreen() {
      this.resetDistributionList();
      this.getDistribution();
    },
    //商户分布（清除）
    clearSearchScreen() {
      this.merchantSearchWord = "";
      this.resetDistributionList();
      this.getDistribution();
    },
    //商户分布列表重置
    resetDistributionList() {
      this.pageNum = "";
      this.merchantList = [];
    },
    //商户分布列表--页码按钮回调
    changePage(num) {
      this.pageNum = num;
      this.getDistribution(1);
    },
    //商户分布--查看详情
    async detailClick(item) {
      if (!item.id) {
        this.$Message.warning("该品牌正在收录中，敬请期待");
        return false;
      }
      //this.$router.push("/brandDetail?id=" + item.id + "");

      const res = await GetDetailBrowsingRecord({
        module: "brandDetail",
        detailID: item.id,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/brandDetail", {
          id: item.id,
        });
      }
    },
    // 商圈区位分析模块
    // 初始化地图
    handleMapLoaded() {
      this.isMapLoaded = true;
      let geom = this.mapPoints[0].geometry;
      let features = [this.mapPoints[0]];
      let options = {
        icon: "/images/marker_blue2.png",
        iconSize: [22, 33],
        anchor: "bottom-center",
      };
      this.mapPanel.setMarkers("markers", features, options);
      this.drawMarkerPolygon(geom);
    },
    // 绘制3公里的圆
    drawMarkerPolygon(polygon) {
      let center = polygon.coordinates;
      let radius = this.markerObj.radius;
      let features = [];
      let circle = turf.circle(center, radius / 1000, {
        steps: 256,
        units: "kilometers",
      });
      this.searchGeom = JSON.stringify(circle.geometry);
      features = [circle];
      let options = {};
      this.mapPanel.setPolygons("marker_circle", features, options);
      if (features.length > 0) {
        this.mapPanel.fitBounds(features, true, [100, 100, 100, 100]);
      }
      this.mapPanel.clearLegends();
      this.queryLegendsPois();
    },
    // 查询poi
    queryLegendsPois() {
      let geom = this.searchGeom;
      for (let i = 0, n = this.legends.length; i < n; i++) {
        this.queryAreaPois(i, geom);
      }
      this.isSearchResult = true;
    },
    // 请求选中面积中的配套信息
    async queryAreaPois(legendIndex, geom) {
      let key = this.legends[legendIndex].key;
      let cate = this.legends[legendIndex].title;
      let identify = this.$store.state.tabsIdentifyLocation[key];
      let fields = cloneObj(this.$store.state.tabsLocationFields[key]);
      fields.push("位置");
      let polygon = geom2wkt(geom);
      let params = {
        identify: identify,
        fields: fields,
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [polygon],
          },
        ],
        pages: {
          page: 1,
          size: 10000,
        },
      };
      let extObj = {
        均价: "元/㎡",
        平均租金: "元/㎡/天",
        占地面积: "㎡",
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        let list = formatFieldsList2(res.data);
        let features = [];
        for (let i = 0, n = list.length; i < n; i++) {
          let item = list[i];
          let attrs = [];
          attrs.push({
            label: "分类",
            value: cate,
          });
          for (let key in item) {
            if (key === "位置") {
              continue;
            }
            let val = item[key];
            if (val) {
              if (extObj[key]) {
                val += extObj[key];
              }
            }
            attrs.push({
              label: key,
              value: val || "--",
            });
          }
          item.attrs = attrs;
          item.isPoi = true;
          let geom = item["位置"];
          if (!geom) {
            continue;
          }
          geom = wkt2geom(geom);
          let feature = {
            type: "Feature",
            properties: item,
            geometry: geom,
          };
          features.push(feature);
        }
        console.log('features', legendIndex, features);
        this.mapPanel.setLegendSource(legendIndex, features);
      }
      // let key = this.legends[legendIndex].key;
      // let field = null;
      // if (key === "subway") {
      //   field = {
      //     field: "交通类型", //field5
      //     cond: 0,
      //     value: ["地铁站"],
      //   };
      // } else if (key === "bus") {
      //   field = {
      //     field: "交通类型", //field5
      //     cond: 0,
      //     value: ["公交站"],
      //   };
      // }
      // let fields = ["geom"];
      // let polygon = geom2wkt(geom);
      // let filters = [
      //   // {
      //   //   field: "key1",
      //   //   cond: 0,
      //   //   value: [this.mallID],
      //   // },
      //   {
      //     field: "geom",
      //     cond: 0,
      //     value: [polygon],
      //   },
      // ];
      // if (field) {
      //   filters.push(field);
      // }
      // let identify = this.$store.state.tabsIdentifyLocation[key];
      // let params = {
      //   identify: identify,
      //   fields: fields,
      //   filters: filters,
      //   pages: {
      //     page: 1,
      //     size: 10000,
      //   },
      // };
      // console.log('params111',params);
      // let res = await GetCommonInfo(params);
      // if (res && res.code === 200) {
      //   let list = res.data.items || [];
      //   let features = [];
      //   for (let i = 0, n = list.length; i < n; i++) {
      //     let item = list[i];
      //     let attrs = [];
      //     item.attrs = attrs;
      //     item.isPoi = true;
      //     let geom = wkb2geom(item[0]);
      //     let feature = {
      //       type: "Feature",
      //       properties: item,
      //       geometry: geom,
      //     };
      //     features.push(feature);
      //   }
      //   this.mapPanel.setLegendSource(legendIndex, features);
      // }
    },

    //开通VIP
    vipClick() {
this.isShow = true;
      // window.open("/vip", "_blank");
    },
  },
  async mounted() {
    let searchKey = this.$route.query.key;
    this.cityName =  this.$route.query.cityName || "";
    if (searchKey) {
      this.searchKey = searchKey;
    }
    //获取地址栏参数
    let getID = this.$route.query.id;
    if (getID) {
      this.mallID = getID;
    }
    //获取详情信息
    await this.getDetail();
    //业态分析
    this.getBusinessData();
    //商户分布
    this.getDistribution();

    this.scrollBox.addEventListener("scroll", this.handleScroll);
    this.mapPanel = this.$refs.mapPanel;
  },
  components: {
    offlineAnalysis,
    onlineAnalysis,
    SummaryCard,
    MenuSide,
    MapPanelLittle,
    PieChart,
    CircumCustomer,
    NoData,
    MapPanel,

    BasicFacilities,
    SupportingFacilities,
    FormatAnalysis,
    ModalUpgrade

  },
};
</script>

<style lang="less">
.detail-main {
  .search-box {
    .ivu-input {
      width: 320px;
      border: none;
      height: 34px;
      padding: 5px 10px;
    }
    .ivu-input-icon-clear {
      cursor: pointer;
    }
  }
}
</style>
<style lang="less">
@import "../../assets/stylesheets/table.less";

.detail-main {
  display: flex;
  flex-wrap: wrap;

  .main-scroll {
    width: calc(100% - 200px);
    height: calc(100% - 101px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
    background: #f6f6fa;
    transition: width 0.3s;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;

    .map-size {
      width: 100%;
      height: 200px;
      background: #d7d7d7;
    }

    .main-size {
      width: 1120px;
      min-width: 1120px;
      margin-top: -62px;
      position: relative;
      z-index: 199;
    }

    .content-item {
      width: 100%;
      height: auto;
      position: relative;
      margin: 20px 0 0 0;
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .item-title {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        box-sizing: border-box;
        padding: 0 0 0 20px;
        border-bottom: 1px solid #e0e0e0;
      }

      .business-size {
        width: 100%;
        height: 273px;
        display: flex;
        box-sizing: border-box;
        padding: 0 30px;

        .business-left {
          width: 500px;
          height: 240px;
          background: #fbfbfe;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.02);
          margin: 20px 30px 0 0;
          display: flex;
          flex-wrap: wrap;

          .left-item {
            width: 25%;
            height: 33.33%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            align-content: center;
            position: relative;

            .left-item-title {
              width: 100%;
              text-align: center;
            }

            .left-item-num {
              width: 100%;
              text-align: center;
            }

            .left-item-line {
              width: 1px;
              height: 25px;
              background: rgba(132, 130, 149, 0.2);
              position: absolute;
              top: 30px;
              right: 0;
            }
          }

          .left-item:nth-child(4n) {
            .left-item-line {
              display: none;
            }
          }
        }

        .business-right {
          width: calc(100% - 560px);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .charts-box {
            width: 100%;
            height: 70%;
          }
        }
      }

      .distribution-size {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding: 30px 0 20px 30px;
        align-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        .distribution-nav {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          box-sizing: border-box;
          padding: 0 30px 0 0;

          .nav-size {
            width: 100%;
            display: flex;
            margin-bottom: 15px;
            align-items: flex-start;
          }
          .nav-type {
            .nav-item {
              margin-bottom: 10px;
            }
          }
          .nav-item-box {
            flex: 1;
            display: inline-flex;
            flex-wrap: wrap;
          }
          .nav-title {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            margin-right: 16px;
          }

          .nav-item {
            font-size: 14px;
            font-weight: 400;
            color: #000000;
            margin-right: 40px;
            cursor: pointer;
          }

          .nav-choose {
            color: #3519fb;
            margin-bottom: 10px;
          }

          .search-box {
            width: 360px;
            height: 36px;
            border: 1px solid #d0d0d0;
            border-radius: 2px;
            box-sizing: border-box;
            margin-top: 5px;
            position: relative;
            padding-right: 40px;

            .search-btn {
              width: 40px;
              height: 34px;
              position: absolute;
              top: 0;
              right: 0;
              border-left: 1px solid #d0d0d0;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              .search-icon {
                width: 17px;
                height: 17px;
              }
            }
          }
        }

        .distribution-list {
          width: 100%;
          height: 342px;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          margin-top: 24px;
          .list-item {
            width: 254px;
            height: 104px;
            background: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            border: 1px solid #eeeeee;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 0 13px;
            position: relative;
            margin: 0 14px 15px 0;
            .img-size {
              width: 80px;
              height: 80px;
              margin-right: 12px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              position: relative;
              .list-item-img {
                width: auto;
                height: auto;
                max-width: 80px;
                max-height: 80px;
              }
              .pic-name {
                width: 54px;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 1.5;
              }
            }
            .img-size::after {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              border: 1px solid rgba(0, 0, 0, 0.1);
              border-radius: 6px;
              z-index: 9;
            }
            .list-item-content {
              width: calc(100% - 92px);
              height: 80px;
              display: flex;
              flex-wrap: wrap;
              align-content: flex-start;
              line-height: 1;
              .list-item-title {
                width: 100%;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                color: #000000;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .list-item-type {
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 20px;
              }
              .list-item-floor {
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 12px;
              }
            }
            .list-more {
              display: flex;
              align-items: center;
              position: absolute;
              right: 12px;
              bottom: 12px;
              color: #3519fb;
              cursor: pointer;
              img {
                width: 5px;
                height: 8px;
                margin-left: 5px;
              }
            }
          }
          .no-data {
            position: relative;
            left: 50%;
            margin-left: -110px;
          }
        }
        .page-size {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 30px 0 0;
          margin: 20px 0 0 0;
        }
      }
      .location-wrap {
        width: 100%;
        height: 580px;
        padding: 30px;
        .location {
          height: 100%;
          border: 1px solid #eeeeee;
          display: flex;
          border-radius: 4px;
          overflow: hidden;
        }
        .location-left {
          width: calc(100% - 420px);
          height: 100%;
        }
        .location-right {
          width: 420px;
          height: 100%;
          position: relative;
          &:hover {
            .expand-btn {
              display: none !important;
            }
          }
        }
      }
    }

    .demo-item {
      width: 1128px;
      margin: 15px 0 0 -4px;
      display: flex;
      position: relative;
      .g-vip-icon2{
        position: absolute;
        left: 114px;
        top: 18px;
        font-size: 16px;
      }
      .g-vip-icon3{
         position: absolute;
        left: 164px;
        top: 18px;
        font-size: 16px;
      }
      img {
        width: 100%;
      }
      .demo-btn {
        width: 200px;
        height: 45px;
        position: absolute;
        top: 122px;
        left: 465px;
        cursor: pointer;
      }
      .demo-btn2 {
        width: 200px;
        height: 45px;
        position: absolute;
        top: 386px;
        left: 464px;
        cursor: pointer;
      }
      .anchor-box {
        width: 100%;
        height: 30px;
        position: absolute;
        opacity: 0;
      }
      .anchor-box1 {
        top: 770px;
        left: 0;
      }
      .anchor-box2 {
        top: 3070px;
        left: 0;
      }
      .anchor-box3 {
        top: 4020px;
        left: 0;
      }
      .anchor-box4 {
        top: 2000px;
        left: 0;
      }
      .anchor-box5 {
        top: 0;
        left: 0;
      }
      .anchor-box6 {
        top: 880px;
        left: 0;
      }
      .anchor-box9 {
        margin-top: 20px;
      }
    }
    .custom-item {
      flex-direction: column;
      margin-top: 20px;
      img {
        width: auto;
      }
    }
    .page-bottom {
      width: 100%;
      height: 60px;
    }
  }

  .main-scroll-change {
    width: calc(100% - 74px);
    transition: width 0.3s;
  }
}
.m-tips {
  padding: 16px;
  font-size: 12px;
}
</style>
