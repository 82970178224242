var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("OverView", {
        attrs: {
          icon: "icon-marker-oval",
          polygon: _vm.polygon,
          list: _vm.overviewList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }