<template>
  <div>
    <OverView
      icon="icon-marker-oval"
      :polygon="polygon"
      :list="overviewList"
    ></OverView>
  </div>
</template>
<script>
import OverView from "../location/OverView.vue";
import { GetCommonInfo } from "@/service";
import { formatFieldsItem } from "js/utils.js";
export default {
  props: ["polygon"],
  data() {
    return {
      overviewList: [
        { label: "周边人口规模", rate: "rate_renkou", unit: "万人" },
        {
          label: "周边房价",
          rate: "rate_xiaoqu_price",
          unit: "元/㎡",
        },
        { label: "住宅区", rate: "rate_xiaoqu_cnt", unit: "个" },
        { label: "写字楼", rate: "rate_office_cnt", unit: "个" },
        { label: "商场", rate: "rate_shangquan_cnt", unit: "个" },
        { label: "便利店", rate: "rate_bianlidian_cnt", unit: "个" },
        { label: "居住人口", rate: "rate_juzhurenkou", unit: "万人" },
        { label: "办公人口", rate: "rate_bangongrenkou", unit: "万人" },
        { label: "到访客流", rate: "rate_daofangkeliu", unit: "万人" },
      ],
      mallId: "",
    };
  },

  methods: {
    initData() {
      for (let i = 0, n = this.overviewList.length; i < n; i++) {
        let item = this.overviewList[i];
        item.pic = "/images/mall/location_overview" + (i + 1) + ".png";
        item.value = "";
      }
      this.getAreaOverviewInfo();
    },
    async getAreaOverviewInfo() {
      let fields = ["人口规模", "二手房房价", "小区", "写字楼", "商场", "便利店"];
      // for (let i = 0, n = this.overviewList.length; i < n; i++) {
      //   let item = this.overviewList[i];
      //   fields.push(item.label);
      // }

      let params = {
        identify: 'sg-data-位置-区域总览',
        fields: fields,
        filters: [
          {
            field: "geom",
            cond: 0,
            value: [this.polygon],
          },
        ],
      };
      let data = await GetCommonInfo(params);
      if (data && data.code === 200) {
        const res = data.data.items ? data.data.items[0] : []
        res.forEach((item, index) => {
          let value1 = item ? parseFloat(item.toFixed(2)) : item;
          // this.overviewList[index].value = value || value === 0 ? value : "--";
          if (index === 0) {
            this.$set(this.overviewList[6], 'value', value1);
          } else {
            this.$set(this.overviewList[index], 'value', value1);
          }
        })
      }

      let identify = "sg-data-商场-商场区位分析-周边基础信息";
      let params1 = {
        identify: identify,
        //fields: ["居住人口", "办公人口", "到访客流"],
        fields: ["周边人口规模", "办公人口", "到访客流"],
        filters: [
          {
            field: "key1",
            cond: 0,
            value: [this.mallId],
          },
        ],
      };
      let res = await GetCommonInfo(params1);
      if (res && res.code === 200) {
        let dataItem = formatFieldsItem(res.data);
        for (let i = 0, n = this.overviewList.length; i < n; i++) {
          let item = this.overviewList[i];
          Object.keys(dataItem).forEach((key, index) => {
            if (item.label === key) {
              let value = dataItem[item.label];
              value = value ? parseFloat(value.toFixed(2)) : value;
              if (i === 0 || i === 7) {
                value = (value / 10000).toFixed(2);
              }
              item.value = value;
              this.overviewList[i].value = value || value === 0 ? value : "--";
              this.$set(this.overviewList[i], value, value);
            }
          })
        }
      }
    },
  },
  mounted() {
    this.mallId = this.$route.query.id;
    this.initData();
  },
  components: {
    OverView,
  },
};
</script>
