var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap detail-main" },
    [
      _c("Header", {
        attrs: { mode: 2, detailType: "商场", searchKey: _vm.searchKey },
      }),
      _c("MenuSide", {
        attrs: { list: _vm.menuList, activeMenu: _vm.activeMenu },
        on: { isOpen: _vm.isMenuOpen, onselect: _vm.menuSelect },
      }),
      _c(
        "div",
        {
          ref: "scroll",
          staticClass: "main-scroll",
          class: _vm.menuOpen ? "" : "main-scroll-change",
        },
        [
          _c(
            "div",
            { staticClass: "map-size" },
            [
              _vm.mapPoints.length != 0
                ? _c("MapPanelLittle", {
                    attrs: { id: 1, markers: _vm.mapPoints },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "main-size" },
            [
              _c("SummaryCard", {
                attrs: {
                  cardType: 4,
                  cardInfo: _vm.detailInfo,
                  isVip: false,
                  collectShow: true,
                  claimShow: false,
                  shareShow: true,
                  detailID: _vm.mallID,
                },
              }),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "quwei" } },
                [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _vm._v(" 商场区位分析 "),
                      _c(
                        "Poptip",
                        {
                          attrs: {
                            trigger: "hover",
                            placement: "right",
                            padding: "0 0",
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "help_icon",
                            attrs: { type: "ios-help-circle-outline" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "m-tips",
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v("商场所在区域周边3000米的区位分析"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "location-wrap" }, [
                    _c("div", { staticClass: "location" }, [
                      _c(
                        "div",
                        { staticClass: "location-left" },
                        [
                          _c("MapPanel", {
                            ref: "mapPanel",
                            attrs: {
                              legendsObj: _vm.legendsObj,
                              ignoreFitChina: true,
                            },
                            on: { loaded: _vm.handleMapLoaded },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "location-right" },
                        [
                          _c("MapListTemplate", [
                            _c(
                              "div",
                              {
                                staticClass: "left-panel",
                                attrs: { slot: "leftPanel" },
                                slot: "leftPanel",
                              },
                              [
                                _c("div", { staticClass: "left-navs-tabs" }, [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.navs, function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: "nav_" + index,
                                          class: {
                                            cur: item.key === _vm.curNav,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.curNav = item.key
                                            },
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.label) + " ")]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "left-tabs-cont" },
                                  [
                                    _vm.curNav === "basicFacilities"
                                      ? [
                                          _vm.wktPolygon
                                            ? _c("BasicFacilities", {
                                                attrs: {
                                                  polygon: _vm.wktPolygon,
                                                  id: _vm.mallID,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _vm.curNav === "supportingFacilities"
                                      ? [
                                          _vm.wktPolygon
                                            ? _c("SupportingFacilities", {
                                                attrs: {
                                                  polygon: _vm.wktPolygon,
                                                  isShowOverView: false,
                                                  detailId: _vm.mallID,
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      : _vm._e(),
                                    _vm.curNav === "formatAnalysis"
                                      ? [
                                          _c("FormatAnalysis", {
                                            attrs: {
                                              detailId: _vm.mallID,
                                              polygon: _vm.wktPolygon,
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "yetai" } },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(" 业态构成 "),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.yetaiLoadingStatus,
                          expression: "yetaiLoadingStatus",
                        },
                      ],
                      staticClass: "business-size",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "business-left" },
                        _vm._l(_vm.echartData1, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: "business_" + index,
                              staticClass: "left-item",
                            },
                            [
                              _c("div", { staticClass: "left-item-title" }, [
                                _vm._v(_vm._s(item.name)),
                              ]),
                              _c("div", { staticClass: "left-item-num" }, [
                                _vm._v(_vm._s(item.value)),
                                _c("span", [_vm._v("家")]),
                              ]),
                              _c("div", { staticClass: "left-item-line" }),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "business-right" },
                        [
                          _c("PieChart", {
                            staticClass: "charts-box",
                            attrs: { echartData: _vm.echartData1 },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "content-item", attrs: { id: "shanghu" } },
                [
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v("商户分布"),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.shanghuLoadingStatus,
                          expression: "shanghuLoadingStatus",
                        },
                      ],
                      staticClass: "distribution-size",
                    },
                    [
                      _c("div", { staticClass: "distribution-nav" }, [
                        _c("div", { staticClass: "nav-size nav-floor" }, [
                          _c("div", { staticClass: "nav-title" }, [
                            _vm._v("商场楼层："),
                          ]),
                          _c(
                            "div",
                            { staticClass: "nav-item-box" },
                            _vm._l(
                              _vm.distributionFloor,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "floor_" + index,
                                    staticClass: "nav-item",
                                    class: item.choose ? "nav-choose" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.floorScreen(item)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "nav-size nav-type" }, [
                          _c("div", { staticClass: "nav-title" }, [
                            _vm._v("行业分类："),
                          ]),
                          _c(
                            "div",
                            { staticClass: "nav-item-box" },
                            _vm._l(
                              _vm.distributionType,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: "type_" + index,
                                    staticClass: "nav-item",
                                    class: item.choose ? "nav-choose" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.typeScreen(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.name) +
                                        "(" +
                                        _vm._s(item.num) +
                                        ") "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "search-box" },
                          [
                            _c("Input", {
                              attrs: {
                                clearable: "",
                                placeholder: "输入商户名、品牌名查询",
                              },
                              on: {
                                "on-enter": _vm.searchScreen,
                                "on-clear": _vm.clearSearchScreen,
                              },
                              model: {
                                value: _vm.merchantSearchWord,
                                callback: function ($$v) {
                                  _vm.merchantSearchWord = $$v
                                },
                                expression: "merchantSearchWord",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "search-btn",
                                on: { click: _vm.searchScreen },
                              },
                              [
                                _c("img", {
                                  staticClass: "search-icon",
                                  attrs: {
                                    src: require("../../assets/images/icon/search.png"),
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "distribution-list" },
                        [
                          _vm._l(_vm.merchantList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: "distribution_" + index,
                                staticClass: "list-item",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "img-size" },
                                  [
                                    item.imgUrl
                                      ? _c("img", {
                                          staticClass: "list-item-img",
                                          attrs: { src: item.imgUrl, alt: "" },
                                        })
                                      : [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "pic-name text-ellipsis2 center-middle-box",
                                            },
                                            [_vm._v(_vm._s(item.title))]
                                          ),
                                          _c("img", {
                                            staticClass: "list-item-img",
                                            attrs: {
                                              src: require("@/assets/images/business/logobg2.png"),
                                              alt: "",
                                            },
                                          }),
                                        ],
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  { staticClass: "list-item-content" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "list-item-title" },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "list-item-type" },
                                      [_vm._v("类别：" + _vm._s(item.type))]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "list-item-floor" },
                                      [_vm._v("楼层：" + _vm._s(item.floor))]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "list-more",
                                    on: {
                                      click: function ($event) {
                                        return _vm.detailClick(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(" 查看详情 "),
                                    _c("img", {
                                      attrs: {
                                        src: require("../../assets/images/icon/more.png"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm.merchantList.length === 0
                            ? _c("NoData", { staticClass: "no-data" })
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm.merchantList.length != 0 && _vm.pageNum
                        ? _c(
                            "div",
                            { staticClass: "page-size" },
                            [
                              _c(
                                "Page",
                                {
                                  attrs: {
                                    "prev-text": "",
                                    "next-text": "",
                                    "class-name": "dt-pager",
                                    "show-total": true,
                                    total: _vm.total,
                                    current: _vm.pageNum,
                                    "page-size": _vm.pageSize,
                                  },
                                  on: { "on-change": _vm.changePage },
                                },
                                [_vm._v(" 共" + _vm._s(_vm.total) + "条 ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "content-item",
                  attrs: { id: "circumCustomer" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "item-title" },
                    [
                      _vm._v(" 周边客群分析 "),
                      _c(
                        "Poptip",
                        {
                          attrs: {
                            trigger: "hover",
                            placement: "right",
                            padding: "0 0",
                          },
                        },
                        [
                          _c("Icon", {
                            staticClass: "help_icon",
                            attrs: { type: "ios-help-circle-outline" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "m-tips",
                              attrs: { slot: "content" },
                              slot: "content",
                            },
                            [
                              _c("p", [
                                _c("span", [
                                  _vm._v("商场所在区域周边3000米的客群分析"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("Icon", {
                        staticClass: "g-vip-icon",
                        attrs: { type: "ios-lock-outline" },
                      }),
                    ],
                    1
                  ),
                  _c("section", { staticClass: "g-vip-section" }, [
                    _c(
                      "div",
                      {
                        staticClass: "g-vip-btn",
                        attrs: { role: "button" },
                        on: { click: _vm.toVip },
                      },
                      [_vm._v("去升级")]
                    ),
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/demo_pointer.png"),
                          width: "464",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "diliver" }),
                  ]),
                  _vm.mapPoints && _vm.mapPoints.length
                    ? _c("CircumCustomer", {
                        attrs: {
                          id: _vm.mallID,
                          identify: "商场",
                          "pos-info": { geom: _vm.mapPoints[0].geometry },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "demo-item", attrs: { id: "keliu" } },
                [
                  _c("Icon", {
                    staticClass: "g-vip-icon2",
                    attrs: { type: "ios-lock-outline" },
                  }),
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/demo/mall_detail_demo3_b.png"),
                      alt: "",
                    },
                  }),
                  _c("div", {
                    staticClass: "demo-btn2",
                    on: { click: _vm.vipClick },
                  }),
                  _c("div", {
                    staticClass: "anchor-box anchor-box1",
                    attrs: { id: "商场到访" },
                  }),
                  _c("div", {
                    staticClass: "anchor-box anchor-box2",
                    attrs: { id: "楼层到访" },
                  }),
                  _c("div", {
                    staticClass: "anchor-box anchor-box3",
                    attrs: { id: "业态到访" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "demo-item", attrs: { id: "khhx" } },
            [
              _c("Icon", {
                staticClass: "g-vip-icon3",
                attrs: { type: "ios-lock-outline" },
              }),
              _c("img", {
                attrs: {
                  src: require("../../assets/images/demo/mall_detail_demo4_b.png"),
                  alt: "",
                },
              }),
              _c("div", {
                staticClass: "demo-btn2",
                on: { click: _vm.vipClick },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "demo-item custom-item", attrs: { id: "depth" } },
            [
              _c(
                "div",
                {
                  ref: "offlineAnalysis",
                  staticClass: "anchor-box8",
                  attrs: { id: "offlineAnalysis" },
                },
                [_c("offline-analysis")],
                1
              ),
              _c(
                "div",
                { staticClass: "anchor-box9", attrs: { id: "onlineAnalysis" } },
                [_c("online-analysis")],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "page-bottom" }),
        ]
      ),
      _c("ModalUpgrade", {
        attrs: { isShow: _vm.isShow },
        on: {
          close: function ($event) {
            _vm.isShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }